import * as React from 'react';
import { cn } from '../../lib/utils';

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    variant?: 'default' | 'destructive' | 'warning';
  }
>(({ className, variant = 'default', ...props }, ref) => {
  const variantStyles = {
    default: 'bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-100',
    destructive: 'bg-red-50 text-red-800 dark:bg-red-900 dark:text-red-100',
    warning: 'bg-amber-50 text-amber-800 dark:bg-amber-900/20 dark:text-amber-200',
  };

  return (
    <div
      ref={ref}
      role="alert"
      className={cn(
        'relative w-full rounded-lg border p-4',
        'flex items-center gap-3',
        '[&>svg]:h-4 [&>svg]:w-4 [&>svg]:shrink-0',
        variantStyles[variant],
        {
          'border-gray-200 dark:border-gray-700': variant === 'default',
          'border-red-200 dark:border-red-800': variant === 'destructive',
          'border-amber-200 dark:border-amber-800': variant === 'warning',
        },
        className
      )}
      {...props}
    />
  );
});
Alert.displayName = 'Alert';

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('text-sm [&_p]:leading-relaxed', className)}
    {...props}
  />
));
AlertDescription.displayName = 'AlertDescription';

export { Alert, AlertDescription };
