// src/components/dashboard/UsageMetrics.tsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Clock, Activity } from 'lucide-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useAuth } from '../../hooks/useAuth';
import { logger } from '../../utils/logger';
import { UsageMetrics as UsageMetricsType, TimeframeOption, APIResponse } from '../../types/dashboard';

const UsageMetrics: React.FC = () => {
  const { t } = useTranslation();
  const { token } = useAuth();
  const [timeframe, setTimeframe] = useState<TimeframeOption>('day');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [usageData, setUsageData] = useState<UsageMetricsType | null>(null);

  const fetchUsageMetrics = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await fetch(`/api/dashboard/usage/metrics?timeframe=${timeframe}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || t('dashboard.usage.errors.fetchFailed'));
      }

      const data: APIResponse<UsageMetricsType> = await response.json();
      if (!data.success || !data.data) {
        throw new Error(t('dashboard.usage.errors.fetchFailed'));
      }

      setUsageData(data.data);
    } catch (error) {
      logger.error('Error fetching usage metrics:', error);
      setError(error instanceof Error ? error.message : t('dashboard.usage.errors.unknown'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsageMetrics();
  }, [timeframe, token, t]);

  if (loading && !usageData) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!usageData) {
    return (
      <Alert variant="destructive">
        <AlertDescription>{error || t('dashboard.usage.errors.noData')}</AlertDescription>
      </Alert>
    );
  }

  const usagePercentage = (usageData.currentUsage / usageData.monthlyLimit) * 100;
  const isNearLimit = usagePercentage >= 80;
  const isOverLimit = usagePercentage >= 100;

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between">
          <div>
            <CardTitle>{t('dashboard.usage.title')}</CardTitle>
            <CardDescription>{t('dashboard.usage.description')}</CardDescription>
          </div>
          <Select 
            value={timeframe} 
            onValueChange={(value: TimeframeOption) => setTimeframe(value)}
          >
            <SelectTrigger className="w-[120px]">
              <Clock className="w-4 h-4 mr-2" />
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="day">{t('dashboard.usage.timeframe.day')}</SelectItem>
              <SelectItem value="week">{t('dashboard.usage.timeframe.week')}</SelectItem>
              <SelectItem value="month">{t('dashboard.usage.timeframe.month')}</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </CardHeader>
      <CardContent>
        {/* Usage Progress */}
        <div className="mb-6">
          <div className="flex justify-between mb-2">
            <span className="text-sm font-medium">
              {t('dashboard.usage.progress.current')}: {usageData.currentUsage.toLocaleString()} {t('dashboard.usage.calls')}
            </span>
            <span className="text-sm text-muted-foreground">
              {t('dashboard.usage.progress.limit')}: {usageData.monthlyLimit.toLocaleString()} {t('dashboard.usage.calls')}
            </span>
          </div>
          <div className="h-2 bg-muted rounded-full">
            <div
              className={`h-2 rounded-full transition-all duration-500 ${
                isOverLimit
                  ? 'bg-destructive'
                  : isNearLimit
                  ? 'bg-warning'
                  : 'bg-primary'
              }`}
              style={{ width: `${Math.min(usagePercentage, 100)}%` }}
            />
          </div>
        </div>

        {/* Usage Chart */}
        {usageData.usageByDay.length > 0 ? (
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={usageData.usageByDay}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="count" stroke="#3b82f6" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div className="text-center py-8 text-muted-foreground">
            {t('dashboard.usage.noData')}
          </div>
        )}

        {/* Refresh Button */}
        <div className="mt-6 flex justify-end">
          <Button
            variant="outline"
            size="sm"
            onClick={fetchUsageMetrics}
            disabled={loading}
          >
            {loading ? (
              <>
                <div className="animate-spin mr-2 h-4 w-4 border-2 border-current border-t-transparent rounded-full" />
                {t('common.loading')}
              </>
            ) : (
              <>
                <Activity className="mr-2 h-4 w-4" />
                {t('common.refresh')}
              </>
            )}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default UsageMetrics;
