// src/components/auth/RequestResetForm.tsx
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '../ui/alert';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { logger } from '../../utils/logger';

const RequestResetForm = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setStatus('idle');
    setError('');

    try {
      const response = await fetch('/api/auth/request-reset', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Failed to request password reset');
      }

      setStatus('success');
      logger.debug('Password reset requested successfully');
    } catch (err) {
      logger.error('Password reset request failed:', err);
      setStatus('error');
      setError(t('auth.resetPassword.requestError'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mx-auto max-w-sm space-y-6">
      <div className="space-y-2 text-center">
        <h1 className="text-2xl font-bold">{t('auth.resetPassword.requestTitle')}</h1>
        <p className="text-muted-foreground">
          {t('auth.resetPassword.requestDescription')}
        </p>
      </div>

      {status === 'success' ? (
        <Alert className="bg-green-50 border-green-200">
          {t('auth.resetPassword.emailSent')}
        </Alert>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-4">
          {error && (
            <Alert variant="destructive">{error}</Alert>
          )}
          <div className="space-y-2">
            <Label htmlFor="email">{t('auth.resetPassword.emailLabel')}</Label>
            <Input
              id="email"
              type="email"
              placeholder={t('auth.resetPassword.emailPlaceholder')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={isLoading}
            />
          </div>
          <Button type="submit" className="w-full" disabled={isLoading}>
            {isLoading ? (
              <>
                <span className="animate-spin mr-2">⚪</span>
                {t('auth.resetPassword.requestingButton')}
              </>
            ) : (
              t('auth.resetPassword.requestButton')
            )}
          </Button>
        </form>
      )}
    </div>
  );
};

export default RequestResetForm;
