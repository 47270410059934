// src/components/auth/ResetPasswordForm.tsx
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert } from '../ui/alert';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { logger } from '../../utils/logger';

const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isTokenValid, setIsTokenValid] = useState(false);

  const token = searchParams.get('token');
  const email = searchParams.get('email');

  useEffect(() => {
    const verifyToken = async () => {
      if (!token || !email) {
        setError(t('auth.resetPassword.invalidLink'));
        return;
      }

      try {
        const response = await fetch(
          `/api/auth/verify-reset-token?token=${token}&email=${encodeURIComponent(email)}`
        );
        const data = await response.json();
        setIsTokenValid(data.success);
        if (!data.success) {
          setError(t('auth.resetPassword.expiredLink'));
        }
      } catch (err) {
        logger.error('Token verification failed:', err);
        setError(t('auth.resetPassword.verificationError'));
      }
    };

    verifyToken();
  }, [token, email, t]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError(t('auth.resetPassword.passwordMismatch'));
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const response = await fetch('/api/auth/reset-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, email, password }),
      });

      if (!response.ok) {
        throw new Error('Failed to reset password');
      }

      logger.debug('Password reset successful');
      navigate('/auth/login', { 
        state: { message: t('auth.resetPassword.success') } 
      });
    } catch (err) {
      logger.error('Password reset failed:', err);
      setError(t('auth.resetPassword.error'));
    } finally {
      setIsLoading(false);
    }
  };

  if (!isTokenValid) {
    return (
      <div className="mx-auto max-w-sm space-y-6">
        <Alert variant="destructive">{error}</Alert>
        <Button
          onClick={() => navigate('/auth/request-reset')}
          className="w-full"
        >
          {t('auth.resetPassword.requestNew')}
        </Button>
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-sm space-y-6">
      <div className="space-y-2 text-center">
        <h1 className="text-2xl font-bold">{t('auth.resetPassword.title')}</h1>
        <p className="text-muted-foreground">
          {t('auth.resetPassword.description')}
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        {error && (
          <Alert variant="destructive">{error}</Alert>
        )}
        
        <div className="space-y-2">
          <Label htmlFor="password">
            {t('auth.resetPassword.newPasswordLabel')}
          </Label>
          <Input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={isLoading}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="confirmPassword">
            {t('auth.resetPassword.confirmPasswordLabel')}
          </Label>
          <Input
            id="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            disabled={isLoading}
          />
        </div>

        <Button type="submit" className="w-full" disabled={isLoading}>
          {isLoading ? (
            <>
              <span className="animate-spin mr-2">⚪</span>
              {t('auth.resetPassword.resettingButton')}
            </>
          ) : (
            t('auth.resetPassword.resetButton')
          )}
        </Button>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
