// src/hooks/useAuth.tsx
import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { logger } from '../utils/logger';

interface User {
  id: string;
  email: string;
  name: string;
  subscriptionId: string;
  lastLogin: Date | null;
  setupCompletedAt: Date | null;
}

interface AuthTokens {
  token: string;
  refreshToken: string;
}

interface LoginResponse {
  success: boolean;
  message?: string;
  data: {
    token: string;
    refreshToken: string;
    user: User;
  };
}

interface TokenVerificationResponse {
  success: boolean;
  message?: string;
  data?: {
    isValid: boolean;
    user?: User;
  };
}

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  isLoading: boolean;
  token: string | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  setInitialPassword: (token: string, email: string, password: string, agencyCode?: string | null) => Promise<void>;
  verifySetupToken: (token: string, email: string) => Promise<TokenVerificationResponse>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const api = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add request interceptor to add Authorization header
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('authToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Add response interceptor for token refresh and error handling
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Avoid infinite loop in case of refresh token failure
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refreshToken');

      if (refreshToken && error.response?.data?.code === 'TOKEN_EXPIRED') {
        logger.debug('Token expired, attempting refresh');
        try {
          const response = await axios.post<LoginResponse>(
            '/api/auth/refresh',
            { refreshToken }
          );

          if (response.data.success) {
            logger.debug('Token refresh successful');
            const { token: newToken, refreshToken: newRefreshToken } = response.data.data;
            
            localStorage.setItem('authToken', newToken);
            localStorage.setItem('refreshToken', newRefreshToken);
            
            api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
            
            return api(originalRequest);
          }
        } catch (refreshError) {
          logger.error('Token refresh failed:', refreshError);
          localStorage.removeItem('authToken');
          localStorage.removeItem('refreshToken');
          window.location.href = '/auth/login';
          return Promise.reject(refreshError);
        }
      }
    }

    return Promise.reject(error);
  }
);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [initialized, setInitialized] = useState(false);

  // Clear auth state helper
  const clearAuthState = useCallback(() => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    delete api.defaults.headers.common['Authorization'];
    setToken(null);
    setUser(null);
    setIsAuthenticated(false);
  }, []);

  // Set auth state helper
  const setAuthState = useCallback((data: { token: string; refreshToken: string; user: User }) => {
    logger.debug('Setting auth state:', { userId: data.user.id, email: data.user.email });
    
    // First, clear any existing state
    clearAuthState();
    
    // Then set new state
    localStorage.setItem('authToken', data.token);
    localStorage.setItem('refreshToken', data.refreshToken);
    setToken(data.token);
    api.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
    setUser(data.user);
    setIsAuthenticated(true);
  }, [clearAuthState]);

  // Initialize auth state
  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const storedToken = localStorage.getItem('authToken');
        const storedRefreshToken = localStorage.getItem('refreshToken');
        
        logger.debug('Initializing auth state:', {
          hasToken: !!storedToken,
          hasRefreshToken: !!storedRefreshToken
        });
        
        if (!storedToken) {
          throw new Error('No stored token');
        }

        setToken(storedToken);
        api.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
        
        const response = await api.get<TokenVerificationResponse>('/auth/verify');
        
        if (response.data.success && response.data.data?.user) {
          setUser(response.data.data.user);
          setIsAuthenticated(true);
          logger.debug('Auth state initialized successfully');
        } else {
          throw new Error('Token verification failed');
        }
      } catch (error) {
        logger.error('Auth initialization failed:', error);
        clearAuthState();
      } finally {
        setIsLoading(false);
        setInitialized(true);
      }
    };

    // Only run initialization once
    if (!initialized) {
      initializeAuth();
    }
  }, [initialized, clearAuthState]);

  // Add token refresh mechanism
  const refreshAuthToken = useCallback(async () => {
    try {
      const storedRefreshToken = localStorage.getItem('refreshToken');
      
      if (!storedRefreshToken) {
        throw new Error('No refresh token available');
      }

      const response = await api.post<LoginResponse>('/auth/refresh', {
        refreshToken: storedRefreshToken
      });

      if (response.data.success) {
        const { token, refreshToken, user } = response.data.data;
        setAuthState({ token, refreshToken, user });
        return true;
      }
      return false;
    } catch (error) {
      logger.error('Token refresh failed:', error);
      clearAuthState();
      return false;
    }
  }, [setAuthState, clearAuthState]);

  // Add automatic token refresh on interval
  useEffect(() => {
    if (isAuthenticated) {
      const refreshInterval = setInterval(() => {
        refreshAuthToken().catch(() => {
          logger.error('Automatic token refresh failed');
        });
      }, 6 * 60 * 60 * 1000); // Refresh every 6 hours

      return () => clearInterval(refreshInterval);
    }
  }, [isAuthenticated, refreshAuthToken]);

  const login = useCallback(async (email: string, password: string) => {
    try {
      logger.debug('Attempting login:', { email });
      const response = await api.post<LoginResponse>('/auth/login', {
        email,
        password,
      });

      if (response.data.success) {
        logger.debug('Login successful');
        const { token, refreshToken, user } = response.data.data;
        setAuthState({ token, refreshToken, user });
      } else {
        logger.error('Login failed:', response.data.message);
        throw new Error(response.data.message || 'Login failed');
      }
    } catch (error) {
      logger.error('Login error:', error);
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 401) {
          throw new Error('Invalid email or password');
        }
        throw new Error(error.response.data?.message || 'Login failed');
      }
      throw error;
    }
  }, [setAuthState]);

  const logout = useCallback(async () => {
    try {
      if (token) {
        logger.debug('Logging out');
        await api.post('/auth/logout');
      }
    } catch (error) {
      logger.error('Logout error:', error);
    } finally {
      clearAuthState();
      logger.debug('Logout completed');
    }
  }, [token, clearAuthState]);

  const verifySetupToken = useCallback(async (
    token: string,
    email: string
  ): Promise<TokenVerificationResponse> => {
    try {
      logger.debug('Verifying setup token:', { email });
      const response = await api.get<TokenVerificationResponse>(
        '/auth/verify-setup-token',
        {
          params: { token, email }
        }
      );
      return response.data;
    } catch (error) {
      logger.error('Token verification error:', error);
      throw error;
    }
  }, []);

  const setInitialPassword = useCallback(async (
    token: string,
    email: string,
    password: string,
    agencyCode?: string | null
  ) => {
    try {
      logger.debug('Setting initial password:', { email });
      const response = await api.post<LoginResponse>('/auth/setup', {
        token,
        email,
        password,
        ...(agencyCode && { agencyCode })
      });

      if (response.data.success) {
        logger.debug('Password setup successful');
        const { token: authToken, refreshToken, user } = response.data.data;
        setAuthState({ token: authToken, refreshToken, user });
      } else {
        logger.error('Password setup failed:', response.data.message);
        throw new Error(response.data.message || 'Failed to set password');
      }
    } catch (error) {
      logger.error('Set password error:', error);
      if (axios.isAxiosError(error) && error.response) {
        throw new Error(error.response.data?.message || 'Failed to set password');
      }
      throw error;
    }
  }, [setAuthState]);

  return (
    <AuthContext.Provider 
      value={{ 
        isAuthenticated, 
        user,
        isLoading: isLoading && !initialized,
        token,
        login, 
        logout, 
        setInitialPassword,
        verifySetupToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

