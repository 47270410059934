import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Plus, Save, Trash2, AlertCircle, Copy, Check } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { useAuth } from '@/hooks/useAuth';
import { logger } from '@/utils/logger';
import AssistantDemo from './AssistantDemo';

interface Feature {
  id: string;
  description: string;
}

interface Assistant {
  id: string;
  customerId: string;
  name: string;
  featureType: 'IMAGE' | 'TEXT' | 'AUDIO';
  features: Feature[];
  configuration: {
    features: Feature[];
  };
  status: string;
  createdAt: string;
  updatedAt: string;
}

interface PlanFeatures {
  planCode: string;
  monthlyApiLimit: number;
  supportedTypes: Array<'IMAGE' | 'TEXT' | 'AUDIO'>;
  maxFeatures: number;
}

interface CreateAssistantRequest {
  name: string;
  featureType: 'IMAGE' | 'TEXT' | 'AUDIO';
  features: Feature[];
}

interface ApiKeyResponse {
  id: string;
  name: string | null;
  key_hash: string;
  created_at: string;
  last_used_at: string | null;
  status: string;
}

const API_BASE_URL = '/api/dashboard';

const AssistantConfiguration: React.FC = () => {
  const { t } = useTranslation();
  const { token } = useAuth();
  const [assistants, setAssistants] = useState<Assistant[]>([]);
  const [apiKeys, setApiKeys] = useState<ApiKeyResponse[]>([]);
  const [planFeatures, setPlanFeatures] = useState<PlanFeatures | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [saveStatus, setSaveStatus] = useState('');
  const [copiedId, setCopiedId] = useState<string | null>(null);
  const [newAssistant, setNewAssistant] = useState<CreateAssistantRequest>({
    name: '',
    featureType: 'IMAGE',
    features: []
  });
  const [newFeature, setNewFeature] = useState('');

  const getSupportedTypes = (planCode: string): Array<'IMAGE' | 'TEXT' | 'AUDIO'> => {
    switch (planCode) {
      case 'STANDARD':
        return ['IMAGE'];
      case 'PRO':
        return ['IMAGE', 'TEXT'];
      case 'ENTERPRISE':
        return ['IMAGE', 'TEXT', 'AUDIO'];
      default:
        return ['IMAGE'];
    }
  };

  const getMaxFeatures = (planCode: string): number => {
    switch (planCode) {
      case 'STANDARD':
        return 1;
      case 'PRO':
        return 10;
      case 'ENTERPRISE':
        return Number.MAX_SAFE_INTEGER;
      default:
        return 1;
    }
  };

  const handleCopyId = async (id: string) => {
    try {
      await navigator.clipboard.writeText(id);
      setCopiedId(id);
      setTimeout(() => setCopiedId(null), 2000);
    } catch (err) {
      logger.error('Failed to copy ID:', err);
    }
  };

  const handleAddFeature = () => {
    if (!newFeature.trim() || !planFeatures) return;

    if (newAssistant.features.length >= planFeatures.maxFeatures) {
      setSaveStatus(t('dashboard.assistant.validation.maxFeatures', { 
        max: planFeatures.maxFeatures 
      }));
      return;
    }

    setNewAssistant(prev => ({
      ...prev,
      features: [...prev.features, { 
        id: crypto.randomUUID(), 
        description: newFeature.trim() 
      }]
    }));
    setNewFeature('');
  };

  const renderDemoSection = (assistant: Assistant) => {
    const processedKeys = apiKeys
      .filter(key => key.status === 'ACTIVE' && key.key_hash)
      .map(key => ({
        id: key.id,
        name: key.name || `Key ${key.id.substring(0, 8)}`,
        keyHash: key.key_hash
      }));

    logger.info('Processing demo keys:', {
      totalKeys: apiKeys.length,
      activeKeys: processedKeys.length,
      keysWithHash: processedKeys.filter(k => k.keyHash).length
    });

    logger.debug('API Keys detail:', apiKeys.map(k => ({
      id: k.id,
      name: k.name,
      hasHash: !!k.key_hash,
      status: k.status
    })));

    return (
      <AssistantDemo
        assistantId={assistant.id}
        availableKeys={processedKeys}
        features={assistant.features}
        featureType={assistant.featureType}
      />
    );
  };

  const handleSaveAssistant = async () => {
    if (!newAssistant.name.trim() || newAssistant.features.length === 0) {
      setSaveStatus(t('dashboard.assistant.validation.required'));
      return;
    }

    try {
      setIsLoading(true);
      setSaveStatus('');
      
      const response = await fetch(`${API_BASE_URL}/assistants`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newAssistant)
      });

      if (!response.ok) {
        throw new Error(t('dashboard.assistant.errors.saveFailed'));
      }

      const result = await response.json();
      setAssistants(prev => [...prev, result.data]);
      setNewAssistant({
        name: '',
        featureType: 'IMAGE',
        features: []
      });
      setSaveStatus(t('dashboard.assistant.saveSuccess'));
    } catch (error) {
      logger.error('Error saving assistant:', error);
      setSaveStatus(error instanceof Error ? error.message : t('dashboard.assistant.saveError'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const [featuresResponse, assistantsResponse, keysResponse] = await Promise.all([
          fetch(`${API_BASE_URL}/plan-features`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }),
          fetch(`${API_BASE_URL}/assistants`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }),
          fetch(`${API_BASE_URL}/keys`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
        ]);

        if (!featuresResponse.ok || !assistantsResponse.ok || !keysResponse.ok) {
          throw new Error(t('dashboard.assistant.errors.fetchFailed'));
        }

        const [featuresResult, assistantsResult, keysResult] = await Promise.all([
          featuresResponse.json(),
          assistantsResponse.json(),
          keysResponse.json()
        ]);

        if (featuresResult.data) {
          setPlanFeatures({
            ...featuresResult.data,
            supportedTypes: getSupportedTypes(featuresResult.data.planCode),
            maxFeatures: getMaxFeatures(featuresResult.data.planCode)
          });
        }

        if (assistantsResult.data) {
          setAssistants(assistantsResult.data);
        }

        if (keysResult.data) {
          setApiKeys(keysResult.data);
          
          logger.info('Received API keys:', {
            total: keysResult.data.length,
            withHash: keysResult.data.filter((k: ApiKeyResponse) => !!k.key_hash).length,
            keys: keysResult.data.map((k: ApiKeyResponse) => ({
              id: k.id,
              hasHash: !!k.key_hash,
              status: k.status
            }))
          });
        }
      } catch (err) {
        logger.error('Error fetching data:', err);
        setError(err instanceof Error ? err.message : t('dashboard.assistant.errors.unknown'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, t]);

  const renderFeatureTypeSelection = () => {
    if (!planFeatures) return null;

    const allowedTypes = planFeatures.supportedTypes;

    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <Label>{t('dashboard.assistant.form.type')}</Label>
          <span className="text-sm text-muted-foreground">
            {t('dashboard.assistant.form.availableTypes', {
              plan: t(`plans.${planFeatures.planCode.toLowerCase()}.name`)
            })}
          </span>
        </div>

        <RadioGroup
          value={newAssistant.featureType}
          onValueChange={(value: 'IMAGE' | 'TEXT' | 'AUDIO') => 
            setNewAssistant(prev => ({ ...prev, featureType: value }))}
          className="flex space-x-4"
        >
          {(['IMAGE', 'TEXT', 'AUDIO'] as const).map((type) => {
            const isAllowed = allowedTypes.includes(type);
            const typeId = `type-${type.toLowerCase()}`;
            
            return (
              <div key={typeId} className="flex items-center space-x-2">
                <RadioGroupItem 
                  value={type} 
                  id={typeId} 
                  disabled={!isAllowed}
                />
                <Label 
                  htmlFor={typeId} 
                  className={!isAllowed ? "text-muted-foreground cursor-not-allowed" : ""}
                >
                  {t(`dashboard.assistant.form.types.${type.toLowerCase()}`)}
                </Label>
              </div>
            );
          })}
        </RadioGroup>

        {!allowedTypes.includes(newAssistant.featureType) && (
          <Alert variant="warning">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              {t('dashboard.assistant.upgradePlan.description')}
            </AlertDescription>
          </Alert>
        )}
      </div>
    );
  };

  const renderFeatureInput = () => (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <Label>{t('dashboard.assistant.form.features')}</Label>
        {planFeatures && (
          <span className="text-sm text-muted-foreground">
            {t('dashboard.assistant.form.featureCount', {
              current: newAssistant.features.length,
              max: planFeatures.maxFeatures
            })}
          </span>
        )}
      </div>

      <div className="space-y-2">
        <Alert className="bg-secondary/10">
          <AlertDescription>
            {t('dashboard.assistant.form.featureInstruction')}
          </AlertDescription>
        </Alert>

        <div className="flex space-x-2">
          <Input
            value={newFeature}
            onChange={(e) => setNewFeature(e.target.value)}
            placeholder={t('dashboard.assistant.form.featurePlaceholder')}
            onKeyPress={(e) => e.key === 'Enter' && handleAddFeature()}
            disabled={isLoading || (planFeatures?.maxFeatures === newAssistant.features.length)}
            className="flex-1"
          />
          <Button 
            onClick={handleAddFeature}
            disabled={!newFeature.trim() || isLoading || (planFeatures?.maxFeatures === newAssistant.features.length)}
            title={planFeatures?.maxFeatures === newAssistant.features.length 
              ? t('dashboard.assistant.form.maxFeaturesReached')
              : t('dashboard.assistant.form.addFeature')}
          >
            <Plus className="h-4 w-4" />
          </Button>
        </div>

        <div className="text-sm text-muted-foreground">
          {t('dashboard.assistant.form.featureExample')}
        </div>
      </div>

      {newAssistant.features.length > 0 && (
        <div className="space-y-2 mt-4">
          {newAssistant.features.map((feature) => (
            <div
              key={feature.id}
              className="flex items-center justify-between p-2 border rounded-md bg-secondary/10"
            >
              <span>{feature.description}</span>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => {
                  setNewAssistant(prev => ({
                    ...prev,
                    features: prev.features.filter(f => f.id !== feature.id)
                  }));
                }}
                disabled={isLoading}
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  if (isLoading && !planFeatures) {
    return (
      <Card className="w-full">
        <CardContent className="p-6">
          <div className="text-center">{t('common.loading')}</div>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="w-full">
        <CardContent className="p-6">
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>{t('dashboard.assistant.title')}</CardTitle>
        <CardDescription>
          {planFeatures && (
            <div className="mt-2">
              <p>{t('dashboard.assistant.description')}</p>
              <div className="mt-2 p-4 bg-secondary/10 rounded-md">
                <div className="font-medium">
                  {t(`plans.${planFeatures.planCode.toLowerCase()}.name`)}
                </div>
                <div className="text-sm text-muted-foreground space-y-1">
                  <div>
                    {t('dashboard.assistant.monthlyLimit', {
                      limit: planFeatures.monthlyApiLimit.toLocaleString()
		      })}
                  </div>
                  <div>
                    {t('dashboard.assistant.supportedTypes', {
                      types: planFeatures.supportedTypes
                        .map(type => t(`dashboard.assistant.form.types.${type.toLowerCase()}`))
                        .join(', ')
                    })}
                  </div>
                  <div>
                    {t('dashboard.assistant.maxFeatures', {
                      count: planFeatures.maxFeatures
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div>
            <Label htmlFor="assistant-name">{t('dashboard.assistant.form.name')}</Label>
            <Input
              id="assistant-name"
              value={newAssistant.name}
              onChange={(e) => setNewAssistant(prev => ({ ...prev, name: e.target.value }))}
              placeholder={t('dashboard.assistant.form.namePlaceholder')}
              disabled={isLoading}
              className="mt-1"
            />
          </div>

          {renderFeatureTypeSelection()}
          {renderFeatureInput()}

          <Button
            onClick={handleSaveAssistant}
            disabled={isLoading || !newAssistant.name.trim() || newAssistant.features.length === 0}
            className="w-full"
          >
            <Save className="h-4 w-4 mr-2" />
            {isLoading ? t('common.saving') : t('dashboard.assistant.form.save')}
          </Button>

          {saveStatus && (
            <Alert
              variant={saveStatus.includes('error') ? 'destructive' : 'default'}
              className="mt-4"
            >
              <AlertDescription>{saveStatus}</AlertDescription>
            </Alert>
          )}

          {assistants.length > 0 && (
            <div className="mt-6">
              <h3 className="text-lg font-medium mb-4">
                {t('dashboard.assistant.savedAssistants')}
              </h3>
              <div className="space-y-4">
                {assistants.map((assistant) => (
                  <div key={assistant.id}>
                    <div className="p-4 border rounded-lg bg-secondary/5 hover:bg-secondary/10 transition-colors">
                      <div className="space-y-4">
                        <div className="flex justify-between items-start">
                          <div>
                            <h4 className="font-medium">{assistant.name}</h4>
                            <div className="flex items-center gap-2 mt-1">
                              <span className="text-sm text-muted-foreground">ID:</span>
                              <div className="flex items-center gap-2">
                                <code className="px-2 py-1 bg-secondary rounded text-sm">
                                  {assistant.id}
                                </code>
                                <Button
                                  variant="ghost"
                                  size="sm"
                                  onClick={() => handleCopyId(assistant.id)}
                                  className="h-8 px-2 hover:bg-secondary"
                                  title={t('common.copyToClipboard')}
                                >
                                  {copiedId === assistant.id ? (
                                    <Check className="h-4 w-4 text-green-500" />
                                  ) : (
                                    <Copy className="h-4 w-4" />
                                  )}
                                </Button>
                              </div>
                            </div>
                          </div>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => {
                              if (window.confirm(t('dashboard.assistant.deleteConfirm'))) {
                                setAssistants(prev =>
                                  prev.filter(a => a.id !== assistant.id)
                                );
                              }
                            }}
                            disabled={isLoading}
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </div>

                        <div className="space-y-2">
                          <div className="flex items-center gap-2">
                            <span className="text-sm font-medium">
                              {t('dashboard.assistant.form.type')}:
                            </span>
                            <span className="text-sm">
                              {t(`dashboard.assistant.form.types.${assistant.featureType.toLowerCase()}`)}
                            </span>
                          </div>

                          <div>
                            <span className="text-sm font-medium">
                              {t('dashboard.assistant.features')}:
                            </span>
                            <ul className="mt-1 space-y-1">
                              {assistant.features.map((feature) => (
                                <li
                                  key={feature.id}
                                  className="text-sm text-muted-foreground ml-4 list-disc"
                                >
                                  {feature.description}
                                </li>
                              ))}
                            </ul>
                          </div>

                          {assistant.updatedAt && (
                            <div className="text-xs text-muted-foreground">
                              {t('dashboard.assistant.lastUpdated')}: {
                                new Date(assistant.updatedAt).toLocaleDateString(
                                  undefined,
                                  {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                  }
                                )
                              }
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {renderDemoSection(assistant)}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default AssistantConfiguration;
