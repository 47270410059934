// src/App.tsx
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './hooks/useAuth';
import RootLayout from './layouts/RootLayout';
import LandingPage from './components/landing/LandingPage';
import LoginForm from './components/auth/LoginForm';
import SetPasswordPage from './pages/auth/SetPasswordPage';
import Dashboard from './components/dashboard/Dashboard';
import NotFound from './components/shared/NotFound';
import DemoPage from './components/landing/DemoPage';
import { logger } from './utils/logger';
import RequestResetForm from './components/auth/RequestResetForm';
import ResetPasswordForm from './components/auth/ResetPasswordForm';

// Enable detailed logging in development
if (import.meta.env.DEV) {
  logger.debug('Development mode - enabling detailed logging');
  localStorage.setItem('debug', '*');
}

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated, isLoading } = useAuth();
  
  logger.debug('PrivateRoute check:', { 
    isAuthenticated, 
    isLoading,
    path: window.location.pathname 
  });

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
          <p className="mt-4 text-muted-foreground">Loading...</p>
        </div>
      </div>
    );
  }

  if (!isAuthenticated) {
    logger.debug('Not authenticated, redirecting to login');
    return <Navigate to="/auth/login" state={{ from: window.location.pathname }} />;
  }

  return <>{children}</>;
};

const AuthRoute = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated, isLoading } = useAuth();

  logger.debug('AuthRoute check:', { 
    isAuthenticated, 
    isLoading,
    path: window.location.pathname 
  });

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
          <p className="mt-4 text-muted-foreground">Loading...</p>
        </div>
      </div>
    );
  }

  if (isAuthenticated) {
    logger.debug('Already authenticated, redirecting to dashboard');
    return <Navigate to="/dashboard" replace />;
  }

  return <>{children}</>;
};

function AppRoutes() {
  logger.debug('AppRoutes rendering');
  
  return (
    <Routes>
      <Route path="/" element={<RootLayout />}>
        <Route index element={<LandingPage />} />
        
        {/* Public routes */}
        <Route path="demo" element={<DemoPage />} />
        
        {/* Auth routes */}
        <Route path="auth">
          <Route
            path="login"
            element={
              <AuthRoute>
                <LoginForm />
              </AuthRoute>
            }
          />
          <Route
            path="setup"
            element={
              <AuthRoute>
                <SetPasswordPage />
              </AuthRoute>
            }
          />
          {/* New password reset routes */}
          <Route
            path="request-reset"
            element={
              <AuthRoute>
                <RequestResetForm />
              </AuthRoute>
            }
          />
          <Route
            path="reset-password"
            element={
              <AuthRoute>
                <ResetPasswordForm />
              </AuthRoute>
            }
          />
        </Route>

        {/* Protected routes */}
        <Route
          path="dashboard/*"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        
        {/* Redirect legacy routes */}
        <Route path="login" element={<Navigate to="/auth/login" replace />} />
        <Route path="set-password" element={<Navigate to="/auth/setup" replace />} />
        
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

function App() {
  logger.debug('App rendering');
  return (
    <AuthProvider>
      <Router>
        <AppRoutes />
      </Router>
    </AuthProvider>
  );
}

export default App;
