// frontend/src/components/DemoPage.tsx

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { Send, AlertCircle } from 'lucide-react';
import { Button } from '../ui/button';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { Alert, AlertDescription } from '../ui/alert';
import { logger } from '../../utils/logger';

interface ApiResponse {
  success: boolean;
  data?: {
    name: string;
    featureType: string;
    configuration: {
      features: Array<{
        id: string;
        description: string;
      }>;
    };
    response?: string;
  };
  message?: string;
  details?: {
    code: number;
    params?: string[];
  };
}

interface FeatureDescription {
  id: string;
  description: string;
}

const API_BASE_URL = import.meta.env.VITE_API_URL || 'https://a.pyunto.com/api';
const API_ENDPOINT = `${API_BASE_URL}/i/v1`;
const DEMO_API_KEY = import.meta.env.VITE_DEMO_API_KEY || 'py_2dc4303140352472a3a26d305779be05e3d3fc2e9708220b';
const DEMO_ASSISTANT_ID = import.meta.env.VITE_DEMO_ASSISTANT_ID || '01e34bde-2def-41b2-8bee-6e68f8ea42d3';

const MAX_FILE_SIZE = 8 * 1024 * 1024; // 8MB

const FileUploadZone = ({ onFileSelect, error, accept }: { 
  onFileSelect: (file: File) => void;
  error: string | null;
  accept: string;
}) => {
  const { t } = useTranslation();
  
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) onFileSelect(file);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) onFileSelect(file);
  };

  return (
    <div
      className={`border-2 border-dashed rounded-lg p-6 text-center ${
        error ? 'border-red-500' : 'border-gray-300'
      }`}
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <input
        type="file"
        className="hidden"
        onChange={handleChange}
        accept={accept}
        id="file-upload"
      />
      <label
        htmlFor="file-upload"
        className="cursor-pointer text-blue-600 hover:text-blue-800"
      >
        {t('demo.upload.dragOrClick')}
      </label>
    </div>
  );
};

const DemoPage = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('demo.pageTitle'));

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [features, setFeatures] = useState<FeatureDescription[]>([]);

  useEffect(() => {
    const fetchAssistantConfig = async () => {
      try {
        logger.debug('Fetching assistant config...'); // デバッグログ
        const response = await fetch(`/api/assistants/demo/${DEMO_ASSISTANT_ID}`, {
          headers: {
            'Accept': 'application/json',
          },
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch assistant configuration');
        }
        
        const result: ApiResponse = await response.json();
        logger.debug('Received assistant config:', result); // デバッグログ

        if (result.success && result.data?.configuration?.features) {
          setFeatures(result.data.configuration.features);
        } else {
          logger.warn('Invalid or empty assistant config:', result); // 警告ログ
          throw new Error(result.message || 'Invalid response format');
        }
      } catch (err) {
        logger.error('Error fetching assistant config:', err);
        setError(t('demo.errors.configFetchFailed'));
      }
    };

    fetchAssistantConfig();
  }, [t]);

  const handleFileSelect = (file: File) => {
    if (file.size > MAX_FILE_SIZE) {
      setError(t('demo.errors.fileTooLarge'));
      return;
    }

    if (!file.type.startsWith('image/')) {
      setError(t('demo.errors.invalidFileType'));
      return;
    }
    
    setSelectedFile(file);
    const url = URL.createObjectURL(file);
    setPreviewUrl(url);
    setError(null);
    setApiResponse(null);

    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      setError(t('demo.errors.noFileSelected'));
      return;
    }

    setIsLoading(true);
    setError(null);
    setApiResponse(null);

    try {
      const arrayBuffer = await selectedFile.arrayBuffer();
      const base64Data = btoa(
        new Uint8Array(arrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      );

      const requestBody = {
        assistantId: DEMO_ASSISTANT_ID,
        type: "image",
        data: base64Data,
        mimeType: selectedFile.type
      };

      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${DEMO_API_KEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
      });

      let result: ApiResponse;
      try {
        result = await response.json();
      } catch (e) {
        console.error('Failed to parse API response:', e);
        throw new Error(t('demo.errors.invalidResponse'));
      }

      if (!response.ok) {
        const errorMessage = result.message || 
          (result.details?.params ? 
            `Missing parameters: ${result.details.params.join(', ')}` : 
            `HTTP error! status: ${response.status}`);
        throw new Error(errorMessage);
      }

      setApiResponse(result);
    } catch (err) {
      logger.error('API call failed:', err);
      setError(err instanceof Error ? err.message : t('demo.errors.processingFailed'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-16">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold mb-8 text-center">
          {t('demo.title')}
        </h1>

        <Card className="mb-8">
          <CardHeader>
            <CardTitle>{t('demo.recognitionItems')}</CardTitle>
          </CardHeader>
          <CardContent>
            {features.length > 0 ? (
              <ul className="list-disc pl-6 space-y-2">
                {features.map((feature) => (
                  <li key={feature.id} className="text-lg">
                    {feature.description}
                  </li>
                ))}
              </ul>
            ) : (
              <div className="text-muted-foreground">
                {error || t('demo.noItems')}
              </div>
            )}
          </CardContent>
        </Card>
        
        <div className="grid md:grid-cols-2 gap-8 mb-8">
          <Card>
            <CardHeader>
              <CardTitle>{t('demo.upload.title')}</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <FileUploadZone
                  onFileSelect={handleFileSelect}
                  error={error}
                  accept="image/*"
                />
                
                {previewUrl && (
                  <div className="mt-4">
                    <img
                      src={previewUrl}
                      alt="Preview"
                      className="max-w-full h-auto rounded-lg"
                    />
                  </div>
                )}

                <Button
                  onClick={handleSubmit}
                  disabled={!selectedFile || isLoading}
                  className="w-full"
                >
                  <Send className="mr-2 h-4 w-4" />
                  {isLoading ? t('demo.processing') : t('demo.analyze')}
                </Button>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>{t('demo.results.title')}</CardTitle>
            </CardHeader>
            <CardContent>
              {error && (
                <Alert variant="destructive">
                  <AlertCircle className="h-4 w-4" />
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
              {apiResponse && (
                <pre className="bg-muted p-4 rounded-lg overflow-auto max-h-[400px] whitespace-pre-wrap">
                  {JSON.stringify(apiResponse.data, null, 2)}
                </pre>
              )}
              {!error && !apiResponse && (
                <div className="text-center text-muted-foreground py-8">
                  {t('demo.results.waiting')}
                </div>
              )}
            </CardContent>
          </Card>
        </div>

        <Card>
          <CardHeader>
            <CardTitle>{t('demo.api.title')}</CardTitle>
          </CardHeader>
          <CardContent>
            <pre className="bg-muted p-4 rounded-lg overflow-auto">
              <code>{`curl -X POST ${API_ENDPOINT} \\
  -H "Authorization: Bearer ${DEMO_API_KEY}" \\
  -H "Content-Type: application/json" \\
  -d '{
    "assistantId": "${DEMO_ASSISTANT_ID}",
    "type": "image",
    "data": "base64_encoded_data",
    "mimeType": "image/jpeg"
  }'`}</code>
            </pre>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default DemoPage;
