import React from 'react';
import { useTranslation } from 'react-i18next';
import { AlertCircle, Code, Copy, Terminal, ArrowRight } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { logger } from '../../utils/logger';

interface Assistant {
  id: string;
  featureType: string;
  features?: Array<{ description: string }>;
}

interface UsageGuideProps {
  apiKey?: string;
  assistant?: Assistant | null;
  isLoading?: boolean;
}

const UsageGuide: React.FC<UsageGuideProps> = ({ apiKey, assistant, isLoading }) => {
  const { t } = useTranslation();
  const [copied, setCopied] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('curl');

  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      logger.error('Failed to copy code:', err);
    }
  };

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>{t('dashboard.guide.title')}</CardTitle>
          <CardDescription>{t('dashboard.guide.description')}</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="text-center py-4 text-muted-foreground">
            {t('common.loading')}
          </div>
        </CardContent>
      </Card>
    );
  }

  const renderSetupStatus = () => {
    if (!apiKey || !assistant) {
      return (
        <Alert className="bg-secondary mb-6">
          <AlertCircle className="h-5 w-5 text-primary" />
          <AlertDescription>
            <div className="space-y-4">
              <h3 className="text-lg font-semibold">
                {t('dashboard.guide.setupStatus')}
              </h3>
              <div className="space-y-2">
                <div className={`flex items-center ${!apiKey ? 'text-primary font-medium' : 'text-muted-foreground'}`}>
                  {!apiKey ? '→' : '✓'} {t('dashboard.guide.setup.createKey')}
                </div>
                <div className={`flex items-center ${apiKey && !assistant ? 'text-primary font-medium' : 'text-muted-foreground'}`}>
                  {!assistant ? '→' : '✓'} {t('dashboard.guide.setup.createAssistant')}
                </div>
              </div>
            </div>
          </AlertDescription>
        </Alert>
      );
    }
    return null;
  };

  const curlCodeImage = `curl -X POST https://a.pyunto.com/api/i/v1 \\
  -H "Authorization: Bearer ${apiKey || 'YOUR_API_KEY'}" \\
  -H "Content-Type: application/json" \\
  -d '{
    "assistantId": "YOUR_ASSISTANT_ID",
    "type": "image",
    "data": "base64_encoded_image",
    "mimeType": "image/jpeg"
  }'`;

  const curlCodeText = `curl -X POST https://a.pyunto.com/api/i/v1 \\
  -H "Authorization: Bearer ${apiKey || 'YOUR_API_KEY'}" \\
  -H "Content-Type: application/json" \\
  -d '{
    "assistantId": "YOUR_ASSISTANT_ID",
    "type": "text",
    "data": "base64_encoded_text",
    "mimeType": "text/plain"
  }'`;

  const pythonCodeImage = `# pi_image_test.py
import base64
import json
import requests

def process_image(api_key, assistant_id, image_path):
    # Read and encode the image
    with open(image_path, 'rb') as image_file:
        encoded_image = base64.b64encode(image_file.read()).decode('utf-8')

    # Prepare the request
    url = 'https://a.pyunto.com/api/i/v1'
    headers = {
        'Authorization': f'Bearer {api_key}',
        'Content-Type': 'application/json'
    }
    payload = {
        'assistantId': assistant_id,
        'type': 'image',
        'data': encoded_image,
        'mimeType': 'image/jpeg'
    }

    # Send the request
    response = requests.post(url, headers=headers, json=payload)
    
    # Print the response
    if response.status_code == 200:
        print('Success!')
        print(json.dumps(response.json(), indent=2))
    else:
        print(f'Error: {response.status_code}')
        print(response.text)

if __name__ == '__main__':
    API_KEY = 'YOUR_API_KEY'
    ASSISTANT_ID = 'YOUR_ASSISTANT_ID'
    IMAGE_PATH = 'image.jpg'

    process_image(API_KEY, ASSISTANT_ID, IMAGE_PATH)`;

  const pythonCodeText = `# pi_text_test.py
import base64
import json
import requests

def process_text(api_key, assistant_id, text_content):
    # Encode the text
    encoded_text = base64.b64encode(text_content.encode('utf-8')).decode('utf-8')

    # Prepare the request
    url = 'https://a.pyunto.com/api/i/v1'
    headers = {
        'Authorization': f'Bearer {api_key}',
        'Content-Type': 'application/json'
    }
    payload = {
        'assistantId': assistant_id,
        'type': 'text',
        'data': encoded_text,
        'mimeType': 'text/plain'
    }

    # Send the request
    response = requests.post(url, headers=headers, json=payload)
    
    # Print the response
    if response.status_code == 200:
        print('Success!')
        print(json.dumps(response.json(), indent=2))
    else:
        print(f'Error: {response.status_code}')
        print(response.text)

if __name__ == '__main__':
    API_KEY = 'YOUR_API_KEY'
    ASSISTANT_ID = 'YOUR_ASSISTANT_ID'
    TEXT_CONTENT = '''Your text content here...'''

    process_text(API_KEY, ASSISTANT_ID, TEXT_CONTENT)`;

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('dashboard.guide.title')}</CardTitle>
        <CardDescription>{t('dashboard.guide.description')}</CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        {renderSetupStatus()}

        <div className="space-y-6">
          <section>
            <h3 className="text-lg font-semibold mb-4">{t('dashboard.guide.overview.title')}</h3>
            <p className="text-muted-foreground mb-4">{t('dashboard.guide.overview.description')}</p>
            <ul className="list-disc ml-6 space-y-2">
              <li>{t('dashboard.guide.overview.step1')}</li>
              <li>{t('dashboard.guide.overview.step2')}</li>
              <li>{t('dashboard.guide.overview.step3')}</li>
            </ul>
          </section>

          <section>
            <h3 className="text-lg font-semibold mb-4">{t('dashboard.guide.authentication.title')}</h3>
            <p className="text-muted-foreground mb-4">{t('dashboard.guide.authentication.description')}</p>
            <pre className="bg-secondary p-4 rounded-lg mb-2">
              <code>Authorization: Bearer YOUR_API_KEY</code>
            </pre>
          </section>

          <section>
            <h3 className="text-lg font-semibold mb-4">{t('dashboard.guide.example.title')}</h3>
            <Tabs defaultValue="image" className="space-y-4">
              <TabsList>
                <TabsTrigger value="image">Image Processing</TabsTrigger>
                <TabsTrigger value="text">Text Processing</TabsTrigger>
              </TabsList>

              <TabsContent value="image" className="space-y-4">
                <div>
                  <h4 className="font-medium mb-2">{t('dashboard.guide.example.curlTitle')}</h4>
                  <div className="relative">
                    <pre className="bg-secondary p-4 rounded-lg overflow-x-auto text-sm">
                      <code>{curlCodeImage}</code>
                    </pre>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="absolute top-2 right-2"
                      onClick={() => handleCopy(curlCodeImage)}
                    >
                      {copied ? <Terminal className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
                    </Button>
                  </div>
                </div>

                <div>
                  <h4 className="font-medium mb-2">{t('dashboard.guide.python.title')}</h4>
                  <div className="relative">
                    <pre className="bg-secondary p-4 rounded-lg overflow-x-auto text-sm">
                      <code>{pythonCodeImage}</code>
                    </pre>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="absolute top-2 right-2"
                      onClick={() => handleCopy(pythonCodeImage)}
                    >
                      {copied ? <Terminal className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
                    </Button>
                  </div>
                </div>
              </TabsContent>

              <TabsContent value="text" className="space-y-4">
                <div>
                  <h4 className="font-medium mb-2">{t('dashboard.guide.example.curlTitle')}</h4>
                  <div className="relative">
                    <pre className="bg-secondary p-4 rounded-lg overflow-x-auto text-sm">
                      <code>{curlCodeText}</code>
                    </pre>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="absolute top-2 right-2"
                      onClick={() => handleCopy(curlCodeText)}
                    >
                      {copied ? <Terminal className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
                    </Button>
                  </div>
                </div>

                <div>
                  <h4 className="font-medium mb-2">{t('dashboard.guide.python.title')}</h4>
                  <div className="relative">
                    <pre className="bg-secondary p-4 rounded-lg overflow-x-auto text-sm">
                      <code>{pythonCodeText}</code>
                    </pre>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="absolute top-2 right-2"
                      onClick={() => handleCopy(pythonCodeText)}
                    >
                      {copied ? <Terminal className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
                    </Button>
                  </div>
                </div>
              </TabsContent>
            </Tabs>

            <div className="mt-4">
              <h4 className="font-medium mb-2">{t('dashboard.guide.response.title')}</h4>
              <pre className="bg-secondary p-4 rounded-lg overflow-x-auto text-sm">
                <code>{`{
  "success": true,
  "data": {
    "results": [
      {
        "feature": "${assistant?.features?.[0]?.description || 'example_feature'}",
        "value": "detected_value",
        "confidence": 0.95
      }
    ],
    "processingTime": "0.5s"
  }
}`}</code>
              </pre>
            </div>
          </section>

          <Alert>
            <Code className="h-4 w-4" />
            <AlertDescription>
              <h4 className="font-semibold mb-2">{t('dashboard.guide.limits.title')}</h4>
              <ul className="list-disc ml-6 space-y-2">
                <li>{t('dashboard.guide.limits.maxSizeImage')} (20MB)</li>
                <li>{t('dashboard.guide.limits.maxSizeText')} (100KB)</li>
                <li>{t('dashboard.guide.limits.supportedFormats')}</li>
                <li>{t('dashboard.guide.limits.rateLimit')}</li>
              </ul>
            </AlertDescription>
          </Alert>

          <section>
            <h3 className="text-lg font-semibold mb-4">{t('dashboard.guide.requirements.title')}</h3>
            <p className="text-sm text-muted-foreground">
              {t('dashboard.guide.python.requirements')}: <code>pip install requests</code>
            </p>
          </section>
        </div>
      </CardContent>
    </Card>
  );
};

export default UsageGuide;
