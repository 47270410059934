import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertCircle, Send } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

interface ApiKeyInfo {
  id: string;
  name: string | null;
  keyHash: string;
}

interface AssistantDemoProps {
  assistantId: string;
  availableKeys: ApiKeyInfo[];
  features: Array<{ id: string; description: string }>;
  featureType: 'IMAGE' | 'TEXT' | 'AUDIO';
}

const MAX_FILE_SIZE = 8 * 1024 * 1024; // 8MB
const API_BASE_URL = '/api/i/v1';

interface FileUploadZoneProps {
  onFileSelect: (file: File) => void;
  error: string | null;
  accept: string;
}

const FileUploadZone: React.FC<FileUploadZoneProps> = ({ onFileSelect, error, accept }) => {
  const { t } = useTranslation();
  
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) onFileSelect(file);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) onFileSelect(file);
  };

  return (
    <div
      className={`border-2 border-dashed rounded-lg p-6 text-center ${
        error ? 'border-red-500' : 'border-gray-300'
      } hover:border-primary transition-colors`}
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <input
        type="file"
        className="hidden"
        onChange={handleChange}
        accept={accept}
        id="file-upload"
      />
      <label
        htmlFor="file-upload"
        className="cursor-pointer text-primary hover:text-primary/80"
      >
        {t('dashboard.assistant.demo.dragOrClick')}
      </label>
    </div>
  );
};

const AssistantDemo: React.FC<AssistantDemoProps> = ({
  assistantId,
  availableKeys,
  features,
  featureType
}) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState<any | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [selectedKeyId, setSelectedKeyId] = useState<string>('');

  useEffect(() => {
    console.log('AssistantDemo mounted with props:', {
      assistantId,
      availableKeysCount: availableKeys.length,
      keys: availableKeys.map(k => ({
        id: k.id,
        name: k.name,
        hasHash: !!k.keyHash
      })),
      featureType,
      featuresCount: features.length
    });

    // Cleanup preview URL on unmount
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [assistantId, availableKeys, features, featureType, previewUrl]);

  const getApiKeyHashById = (id: string) => {
    const foundKey = availableKeys.find(key => key.id === id);
    if (!foundKey || !foundKey.keyHash) {
      console.log('No key or hash found for ID:', id);
      return null;
    }
    console.log('Found key hash for ID:', id);
    return foundKey.keyHash;
  };

  const handleFileSelect = (file: File) => {
    console.log('File selected:', {
      name: file.name,
      type: file.type,
      size: file.size
    });

    if (file.size > MAX_FILE_SIZE) {
      setError(t('dashboard.assistant.demo.errors.fileTooLarge'));
      return;
    }

    const expectedType = featureType.toLowerCase();
    console.log('Checking file type:', {
      fileType: file.type,
      expectedType: `${expectedType}/`
    });

    if (!file.type.startsWith(`${expectedType}/`)) {
      setError(t('dashboard.assistant.demo.errors.invalidFileType'));
      return;
    }
    
    setSelectedFile(file);
    const url = URL.createObjectURL(file);
    setPreviewUrl(url);
    setError(null);
    setApiResponse(null);

    console.log('File accepted and state updated');
  };

  const handleSubmit = async () => {
    console.log('Submit initiated with:', {
      selectedKeyId,
      hasFile: !!selectedFile,
      assistantId
    });

    if (!selectedKeyId) {
      setError(t('dashboard.assistant.demo.errors.noApiKey'));
      return;
    }

    if (!selectedFile) {
      setError(t('dashboard.assistant.demo.errors.noFileSelected'));
      return;
    }

    setIsLoading(true);
    setError(null);
    setApiResponse(null);

    try {
      const keyHash = getApiKeyHashById(selectedKeyId);
      
      if (!keyHash) {
        throw new Error(t('dashboard.assistant.demo.errors.invalidApiKey'));
      }

      const arrayBuffer = await selectedFile.arrayBuffer();
      const base64Data = btoa(
        new Uint8Array(arrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      );

      const requestBody = {
        assistantId,
        type: featureType.toLowerCase(),
        data: base64Data,
        mimeType: selectedFile.type
      };

      console.log('Sending request with hash authorization');

      const response = await fetch(API_BASE_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Hash ${keyHash}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
      });

      console.log('Response status:', response.status);

      if (!response.ok) {
        const errorData = await response.json();
        console.log('Error response:', errorData);
        throw new Error(errorData.message || t('dashboard.assistant.demo.errors.processingFailed'));
      }

      const result = await response.json();
      console.log('Success response received');
      setApiResponse(result);
    } catch (err) {
      console.error('API call failed:', err);
      setError(err instanceof Error ? err.message : t('dashboard.assistant.demo.errors.unknown'));
    } finally {
      setIsLoading(false);
      console.log('Request completed');
    }
  };

  return (
    <Card className="mt-6">
      <CardHeader>
        <CardTitle>{t('dashboard.assistant.demo.title')}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div className="bg-secondary/10 p-4 rounded-lg">
            <h3 className="font-medium mb-2">
              {t('dashboard.assistant.demo.features')}
            </h3>
            <ul className="list-disc pl-6 space-y-1">
              {features.map((feature) => (
                <li key={feature.id}>{feature.description}</li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="font-medium mb-2">
              {t('dashboard.assistant.demo.apiKey')}
            </h3>
            <Select 
              value={selectedKeyId}
              onValueChange={(value) => {
                console.log('API Key selected:', value);
                setSelectedKeyId(value);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder={t('dashboard.assistant.demo.selectApiKey')} />
              </SelectTrigger>
              <SelectContent>
                {availableKeys.map((key) => (
                  <SelectItem key={key.id} value={key.id}>
                    {key.name || `Key ${key.id.substring(0, 8)}`}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {!selectedKeyId && (
              <p className="text-sm text-muted-foreground mt-1">
                {t('dashboard.assistant.demo.apiKeyRequired')}
              </p>
            )}
          </div>

          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <h3 className="font-medium mb-3">
                {t('dashboard.assistant.demo.upload.title')}
              </h3>
              <div className="space-y-4">
                <FileUploadZone
                  onFileSelect={handleFileSelect}
                  error={error}
                  accept={`${featureType.toLowerCase()}/*`}
                />
                
                {previewUrl && featureType === 'IMAGE' && (
                  <div className="mt-4">
                    <img
                      src={previewUrl}
                      alt="Preview"
                      className="max-w-full h-auto rounded-lg"
                    />
                  </div>
                )}

                <Button
                  onClick={handleSubmit}
                  disabled={!selectedFile || !selectedKeyId || isLoading}
                  className="w-full"
                >
                  <Send className="mr-2 h-4 w-4" />
                  {isLoading ? t('dashboard.assistant.demo.processing') : t('dashboard.assistant.demo.analyze')}
                </Button>
              </div>
            </div>

            <div>
              <h3 className="font-medium mb-3">
                {t('dashboard.assistant.demo.results.title')}
              </h3>
              {error && (
                <Alert variant="destructive">
                  <AlertCircle className="h-4 w-4" />
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
              {apiResponse && (
                <pre className="bg-muted p-4 rounded-lg overflow-auto max-h-[400px] whitespace-pre-wrap">
                  {JSON.stringify(apiResponse.data, null, 2)}
                </pre>
              )}
              {!error && !apiResponse && (
                <div className="text-center text-muted-foreground py-8">
                  {t('dashboard.assistant.demo.results.waiting')}
                </div>
              )}
            </div>
          </div>

          <div>
            <h3 className="font-medium mb-3">
              {t('dashboard.assistant.demo.api.title')}
            </h3>
            <pre className="bg-muted p-4 rounded-lg overflow-auto">
              <code>{`curl -X POST ${API_BASE_URL} \\
  -H "Authorization: Bearer YOUR_API_KEY" \\
  -H "Content-Type: application/json" \\
  -d '{
    "assistantId": "${assistantId}",
    "type": "${featureType.toLowerCase()}",
    "data": "base64_encoded_data",
    "mimeType": "${featureType.toLowerCase()}/jpeg"
  }'`}</code>
            </pre>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default AssistantDemo;
