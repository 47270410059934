// src/components/dashboard/AccountSettings.tsx
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Lock } from 'lucide-react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { useAuth } from '../../hooks/useAuth';
import { logger } from '../../utils/logger';

interface PasswordChangeResponse {
  success: boolean;
  message: string;
}

const AccountSettings = () => {
  const { t } = useTranslation();
  const { token } = useAuth();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const validatePasswords = () => {
    if (newPassword.length < 8) {
      setError(t('dashboard.settings.password.errors.length'));
      return false;
    }

    if (newPassword === currentPassword) {
      setError(t('dashboard.settings.password.errors.same'));
      return false;
    }

    if (newPassword !== confirmPassword) {
      setError(t('dashboard.settings.password.errors.mismatch'));
      return false;
    }

    return true;
  };

  const resetForm = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const handlePasswordChange = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    if (!validatePasswords()) {
      return;
    }

    try {
      setIsLoading(true);

      const response = await fetch('/api/auth/password', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          currentPassword,
          newPassword
        })
      });

      const data: PasswordChangeResponse = await response.json();

      if (!response.ok) {
        throw new Error(data.message || t('dashboard.settings.password.errors.updateFailed'));
      }

      setSuccess(t('dashboard.settings.password.success'));
      resetForm();
    } catch (error) {
      const message = error instanceof Error ? error.message : t('dashboard.settings.password.errors.unknown');
      setError(message);
      logger.error('Password change failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <div className="flex items-center gap-2">
            <Lock className="h-5 w-5" />
            <CardTitle>{t('dashboard.settings.password.title')}</CardTitle>
          </div>
          <CardDescription>{t('dashboard.settings.password.description')}</CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handlePasswordChange} className="space-y-4">
            <div>
              <Label htmlFor="current-password">
                {t('dashboard.settings.password.currentPassword')}
              </Label>
              <Input
                id="current-password"
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="mt-1"
                required
                disabled={isLoading}
              />
            </div>
            <div>
              <Label htmlFor="new-password">
                {t('dashboard.settings.password.newPassword')}
              </Label>
              <Input
                id="new-password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="mt-1"
                required
                disabled={isLoading}
                minLength={8}
              />
              <p className="text-sm text-muted-foreground mt-1">
                {t('dashboard.settings.password.requirements')}
              </p>
            </div>
            <div>
              <Label htmlFor="confirm-password">
                {t('dashboard.settings.password.confirmPassword')}
              </Label>
              <Input
                id="confirm-password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="mt-1"
                required
                disabled={isLoading}
              />
            </div>
            <Button
              type="submit"
              disabled={!currentPassword || !newPassword || !confirmPassword || isLoading}
              className="w-full"
            >
              {isLoading
                ? t('dashboard.settings.password.updating')
                : t('dashboard.settings.password.updateButton')}
            </Button>
          </form>

          {error && (
            <Alert variant="destructive" className="mt-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {success && (
            <Alert className="mt-4">
              <AlertDescription>{success}</AlertDescription>
            </Alert>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default AccountSettings;
